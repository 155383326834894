import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/rootReducer';
import { setUserInfo } from '../store/login/loginSlice';
import { useDispatch } from 'react-redux';

const Location: React.FC = () => {
    const dispatch = useDispatch()
    const userInfo = useSelector((state: RootState) => state?.userDetails)

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    let obj = JSON.parse(JSON.stringify(userInfo))
                    obj.userInfo.lat = position.coords.latitude;
                    obj.userInfo.long = position.coords.longitude;
                    dispatch(setUserInfo(obj.userInfo))
                },
                error => {
                    console.log('Location error: ', error.message)
                }
            );
        } else {
            console.log('Geolocation is not supported by this browser. ' )
        }
    };

    useEffect(() => {
        getLocation()
    }, [])

    return (<> </>);
};

export default Location;
