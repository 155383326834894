import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, Button, Box, Tabs, Tab, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ModalPopup from "../../common/ModalPopup";
import axios from "axios";
import Loader from "../../common/Loader";
import WorkerList from "../worker/WorkerList";
import WorkList from "./WorkList";
import WorkByMe from "./WorkByMe";
import { RootState } from "../../store/rootReducer";
import { useSelector } from "react-redux";

export default function GetWorks() {
    const { userInfo } = useSelector((state: RootState) => state?.userDetails)
    const navigate = useNavigate()
    const [tabValue, setTabValue] = useState(0);
    const [addWorkFlag, setAddWorkFlag] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [workersList, setWorkerList] = useState<{ firstName: string; lastName: string; phone: string }[]>([])
    const [worksList, setWorkList] = useState<{ address: string; description: string }[]>([])
    const [workByMeList, setWorkByMe] = useState<{ address: string; description: string, id: string }[]>([])
    const [workerFlag, setWorkerFlag] = useState<boolean>(false)
    const [editWork, setEditWork] = useState<string>('')

    const [workDetails, setWorkDetails] = useState<{ address: string; description: string }>({
        address: '',
        description: ''
    })

    const [workerDetails, setWorkerDetails] = useState<{ skills: string; location: string }>({
        skills: '',
        location: ''
    })

    const [formValidation, setFormValidation] = useState<any>({
        address: false,
        description: false
    })

    const [formValidationWorker, setFormValidationWorker] = useState<boolean>(false)
    const handleWorkChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setWorkDetails({
            ...workDetails, [name]: value
        })
    }

    const validateForm = () => {
        const formData = { ...workDetails }
        const validate: string[] = [];
        const errorObj = {
            address: false,
            description: false
        }
        if (formData.address === '') {
            errorObj.address = true
            validate.push('error')
        }
        if (formData.description === '') {
            errorObj.description = true
            validate.push('error')
        }
        setFormValidation({ ...errorObj })
        return validate.includes('error') ? false : true
    }

    const handleChange = (event: any, newValue: any) => {
        setTabValue(newValue);
        if (newValue === 1) {
            getWorkCreatedByMe()
        } else {
            getWorkList()
        }
    };

    const handleAddWork = () => {
        if (userInfo?.email) {
            setAddWorkFlag(true);
            setEditWork('');
        } else navigate('/')
    }
    const handleBecameWorker = async () => {
        if (userInfo?.email) {
            setWorkerFlag(true)

        } else navigate('/')
    }

    const handleAddWorkClose = () => {
        setAddWorkFlag(false)
    }

    const handleAddWorkSave = async () => {
        if (validateForm()) {
            try {
                setOpen(true)
                const payload = { latitude: userInfo?.lat || 0, longitude: userInfo?.long || 0, email: userInfo?.email, address: workDetails?.address, description: workDetails?.description, id: '' }
                if (editWork !== '') {
                    payload.id = editWork
                    const response = await axios.put("editwork.php", payload);
                } else {
                    const response = await axios.post("addwork.php", payload);
                }
                setOpen(false)
                handleAddWorkClose()
                getWorkCreatedByMe()
                getWorkList()

            }
            catch (error) {
                console.log(error)
            }
        }

    }

    useEffect(() => {
        if (userInfo?.lat && userInfo?.lat) {
            getWorkList()
            getWorkerList()
        }
    }, [userInfo?.lat, userInfo?.lat])

    const getWorkerList = async () => {
        try {
            setOpen(true)
            const payload = { latitude: userInfo?.lat || 0, longitude: userInfo?.long || 0, email: userInfo?.email}
            const response = await axios.post("getworker.php", payload);
            setWorkerList(response.data)
            setOpen(false)
        }
        catch (error) {
            console.log(error)
        }

    }

    const getWorkList = async () => {
        try {
            setOpen(true)
            const payload = { latitude: userInfo?.lat || 0, longitude: userInfo?.long || 0, email: userInfo?.email }
            const response = await axios.post("getworklist.php", payload);
            setWorkList(response.data)
            setOpen(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getWorkCreatedByMe = async () => {
        try {
            setOpen(true)
            const payload = { email: userInfo?.email }
            const response = await axios.post("getworklistbyme.php", payload);
            setWorkByMe(response.data)
            setOpen(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleWorkerClose = () => {
        setWorkerFlag(false)
    }

    const handleWorkerChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setWorkerDetails({
            ...workerDetails, [name]: value
        })
    }

    const saveBecomeWorker = async () => {
        const formData = { ...workerDetails }
        if (formData.skills === '') {
            setFormValidationWorker(true)
        } else {
            try {
                setOpen(true)
                const payload = { isWorker: true, email: userInfo?.email, skills: workerDetails.skills, location: workerDetails.location }
                const response = await axios.put("markuser.php", payload);
                setOpen(false)
                handleWorkerClose()
                getWorkerList()
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleEdit = (id: string) => {
        setEditWork(id)
        setAddWorkFlag(true)
    }

    const markDone = async(id: string)=>{
        try{
            setOpen(true)
            const payload = { email: userInfo?.email,  isDone:true, id: id }
            const response = await axios.put("markdone.php", payload);
            getWorkCreatedByMe()
        }catch(error){
            console.log(error)
        }
    }
    return (
        <Container component="main" maxWidth={false} sx={{ minHeight: '600px' }}>
            <Loader open={open} setOpen={setOpen} />
            <div style={{ padding: '20px' }}>
                <Button sx={{ float: 'right' }} onClick={handleAddWork}>
                    Add Work
                </Button>
                <Button sx={{ float: 'right' }} onClick={handleBecameWorker}>
                    Become a worker
                </Button>
                <Grid container>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabValue} onChange={handleChange} aria-label="Work tabs">
                                <Tab style={{ textTransform: 'capitalize' }} label="Work at my location" />
                                <Tab disabled={userInfo?.email ? false : true} style={{ textTransform: 'capitalize' }} label="Work Created by me" />
                            </Tabs>
                        </Box>
                        <Grid container>
                            <Grid item xs={12} sm={8} md={8}>
                                {tabValue === 0 && <WorkList list={worksList} />}
                                {tabValue === 1 && <WorkByMe markDone={markDone} list={workByMeList} handleEdit={handleEdit} />}
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} >
                                <Grid sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' }, paddingLeft: '20px' }}>
                                    {userInfo?.email ? <WorkerList list={workersList} /> : 'Please login to get the workers list'}
                                </Grid>
                                <Grid sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' }, borderTop: 1, borderColor: 'divider', pt:'5px' }}>
                                    {userInfo?.email ? <WorkerList list={workersList} /> : 'Please login to get the workers list'}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </div>
            <ModalPopup cancelLable="Cancel" saveLable="Save" open={addWorkFlag} handleClose={handleAddWorkClose} header={editWork ? 'Edit Work' : 'Add Work'} handleCancel={handleAddWorkClose} handleSave={handleAddWorkSave} >
                <Grid container>
                    <Grid item xs={12} padding={'10px'}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            autoFocus={true}
                            id="address"
                            label="Work Address"
                            name="address"
                            size="medium"
                            onChange={handleWorkChange}
                            error={formValidation.address}
                            helperText={formValidation.address ? 'Please fill the address of work' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} padding={'10px'}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            id="description"
                            label="Work Description"
                            name="description"
                            size="medium"
                            multiline
                            minRows={3}
                            onChange={handleWorkChange}
                            error={formValidation.description}
                            helperText={formValidation.description ? 'Please describe your work' : ''}
                        />
                    </Grid>
                </Grid>
            </ModalPopup>
            <ModalPopup cancelLable="Cancel" saveLable="Save" open={workerFlag} handleClose={handleWorkerClose} header={'Become a worker'} handleCancel={handleWorkerClose} handleSave={saveBecomeWorker} >
                <Grid container>
                    <Grid item xs={12} padding={'10px'}>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            autoFocus={true}
                            id="skills"
                            label="What you can do"
                            name="skills"
                            size="medium"
                            multiline
                            minRows={2}
                            onChange={handleWorkerChange}
                            error={formValidationWorker}
                            helperText={formValidationWorker ? 'Please fill the skills' : ''}
                        />
                    </Grid>
                    <Grid item xs={12} padding={'10px'}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="location"
                            label="Location"
                            name="location"
                            size="medium"
                            multiline
                            minRows={3}
                            onChange={handleWorkerChange}
                        />
                    </Grid>
                </Grid>
            </ModalPopup>
        </Container>
    );
}
