import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { RootState } from '../../store/rootReducer';
import { setUserInfo } from '../../store/login/loginSlice';
const navItems = ['Get Work', 'Services'];

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state: RootState) => state?.userDetails)
  const handleClick = (e: any, name: string) => {
    if (name === 'Get Work') {
      navigate('/getwork')
    }
  }
  const handleGetItDone = () => {
    navigate('/')
  }

  const handleLogout = () => {
    let obj = JSON.parse(JSON.stringify(userInfo))
    obj.firstName = '';
    obj.lastName = '';
    obj.email = '';
    dispatch(setUserInfo(obj))
    navigate('/')
  }
  return (
    <AppBar sx={{ backgroundColor: '#2c4e69' }} component="div" position='static'>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          sx={{ display: { sm: 'block' } }}
        >
        </IconButton>
        <Typography
          variant="h6"
          component="div"
          onClick={handleGetItDone}
          sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' }, cursor: 'pointer' }}
        >
          Get It Done
        </Typography>
        <Box sx={{ display: { xs: 'block', sm: 'block' } }}>
          {navItems.map((item) => (
            <Button onClick={(e) => handleClick(e, item)} key={item} sx={{ color: '#fff', textTransform: 'capitalize' }}>
              {item}
            </Button>
          ))}
          {userInfo?.email ?
            <Button color='primary' onClick={handleLogout} sx={{
              color: '#fff', backgroundColor: '#08223c', '&:hover': {
                backgroundColor: '#08223c',
                color: 'white',
              }
            }}>
              <Box sx={{ display: { textTransform: 'uppercase !important', xs: 'block', sm: 'none', md: 'none', lg: 'none' } }} component={'span'}>{`${userInfo?.firstName?.charAt(0)}${userInfo?.lastName?.charAt(0)}`}</Box>
              <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block', lg: 'block' } }} component={'span'}>{`${userInfo?.firstName} ${userInfo?.lastName}`}</Box>
              <LogoutOutlinedIcon sx={{ pl: '10px' }} fontSize='small' />
            </Button>
            : ''}
        </Box>
        <Box>

        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
