import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface Props {
  onClose: any;
  open: boolean;
  message:any
}

export default function AlertPopup(props: Props) {
  const { open, onClose, message } = props

  return (
    <div>
      <Snackbar open={open} autoHideDuration={null} onClose={onClose} anchorOrigin={{
        vertical:'top',
        horizontal:'center'
      }}>
        <Alert
          onClose={onClose}
          severity={message?.status === 'Failed'?'error':'success'}
          sx={{ width: '100%' }}
        >
          {message?.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
