import React from "react";
import {
    Routes,
    Route,
    Router,
} from "react-router-dom";
import Header from "../views/header/Header";
import Content from "../views/main/Content";
import GetWorks from "../views/worklist/GetWorks";
import Footer from "../views/footer/Footer";
import Privacypolicy from "../views/privacy/Privacypolicy";

const RouterConfig = () => {
    return <>
        <Header />
            <Routes>
                <Route
                    path="/"
                    element={<Content />}
                ></Route>
                <Route
                    path="/getwork"
                    element={<GetWorks />}
                ></Route>
                <Route
                    path="/policy"
                    element={<Privacypolicy />}
                ></Route>
            </Routes>
        <Footer />
    </>
}

export default RouterConfig