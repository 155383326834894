import React, { useContext } from 'react';
import { Typography, Container, Button, Grid, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import PhonelinkOutlinedIcon from '@mui/icons-material/PhonelinkOutlined';
import MiscellaneousServicesOutlinedIcon from '@mui/icons-material/MiscellaneousServicesOutlined';
import ArrowRightAltOutlinedIcon from '@mui/icons-material/ArrowRightAltOutlined';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const Home = () => {
  const { userInfo } = useSelector((state: RootState) => state?.userDetails)
  const navigate = useNavigate()
  return (
    <>
      <Container maxWidth={false} sx={{ p: '20px !important', color: '#fff', backgroundColor: '#2c4e69', minHeight: '400px' }} >
        <br />
        <Typography variant="h2" color="inherit" sx={{ textTransform: 'capitalize' }}>
          Welcome Dear <strong>{`${userInfo?.firstName} ${userInfo?.lastName}`}</strong>
        </Typography><br /> <br />
        <Typography variant="h4" color="inherit" >
          Explore the list of available opportunity, <br /> help others and get some money.<br />
          <Button size={'medium'} onClick={() => navigate('/getwork')} sx={{
            color: '#fff', backgroundColor: '#08223c', '&:hover': {
              backgroundColor: '#08223c',
              color: 'white',
            }
          }}>
            Explore available work <ArrowRightAltOutlinedIcon fontSize='large' />
          </Button>
        </Typography>
      </Container>
      <Container maxWidth={false} sx={{ p: '10px 40px 0px 40px !important', color: 'black', backgroundColor: 'rgb(238 232 232)', minHeight: '540px' }} >
        <Typography variant="h4" textAlign={"center"} color="inherit" sx={{ textTransform: 'capitalize' }}>
          Our Services
        </Typography>
        <Typography textAlign={"center"} color="inherit" sx={{ textTransform: 'none' }}>
          A solution for every need
        </Typography>
        <Grid container spacing={10} >
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ minWidth: 'auto', mt: '20px' }} >
              <CardContent >
                <Typography gutterBottom component="div" textAlign={'center'}>
                  <PhonelinkOutlinedIcon sx={{ color: '#2c4e69', minHeight: '100px', minWidth: '100px', alignContent: 'center' }} fontSize='large' />
                </Typography>
                <Typography gutterBottom textAlign={'center'} variant="h5" component="div">
                  Application Development
                </Typography>
                <Typography textAlign={'center'} variant="body2" color="text.secondary">
                  We are a group of enthusiast developer and we are always ready to take up new challenges<br />
                  <Link textAlign={'center'} sx={{ textDecoration: 'none' }}>
                    More...
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ minWidth: 'auto', mt: '20px' }} >
              <CardContent >
                <Typography gutterBottom component="div" textAlign={'center'}>
                  <Diversity3OutlinedIcon sx={{ color: '#2c4e69', minHeight: '100px', minWidth: '100px', alignContent: 'center' }} fontSize='large' />
                </Typography>
                <Typography gutterBottom textAlign={'center'} variant="h5" component="div">
                  Skilled Worker
                </Typography>
                <Typography textAlign={'center'} variant="body2" color="text.secondary">
                  We have group of authorised skilled worker, for daily and professional work<br />
                  <Link textAlign={'center'} sx={{ textDecoration: 'none' }}>
                    More...
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Card sx={{ minWidth: 'auto', mt: '20px' }} >
              <CardContent >
                <Typography gutterBottom component="div" textAlign={'center'}>
                  <MiscellaneousServicesOutlinedIcon sx={{ color: '#2c4e69', minHeight: '100px', minWidth: '100px', alignContent: 'center' }} fontSize='large' />
                </Typography>
                <Typography gutterBottom textAlign={'center'} variant="h5" component="div">
                  Other Services
                </Typography>
                <Typography textAlign={'center'} variant="body2" color="text.secondary">
                  Planty of other services we are offring at your location, we are always eager to help and provide a better solution<br />
                  <Link textAlign={'center'} sx={{ textDecoration: 'none' }}>
                    More...
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>

  );
};

export default Home;
