import React from 'react';
import { AppBar, Toolbar, Typography, Container, IconButton, Button } from '@mui/material';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const history = useNavigate()
  return (
    <AppBar sx={{ backgroundColor: 'rgb(238 232 232)', color: 'black', bottom: 0, left: 0 }} position='static' component='div'>
      <Container maxWidth="md">
        <Toolbar>
          
          <Typography variant="body1" color="inherit">
            &#169;  {`${new Date().getFullYear()} Get It Done |`} <Typography sx={{cursor:'pointer'}} color="textSecondary" component='span' onClick={()=>history('/policy')}>
                   Privacy Policy
                </Typography>
          </Typography>
          <Typography sx={{ml:'40px'}} variant="body1" color="textSecondary">
            Follow Us:
          </Typography>
          <IconButton  aria-label="Facebook">
            <FacebookIcon style={{ color: '#3b5998' }} />
          </IconButton>
          <IconButton  aria-label="Twitter">
            <TwitterIcon  style={{ color: '#1da1f2' }} />
          </IconButton>
          <IconButton  aria-label="Instagram">
            <InstagramIcon style={{ color: '#c13584' }} />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Footer;