import React, { useEffect, useState } from 'react';
import { Container, Paper, Typography, Grid } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Pagination from '@mui/material/Pagination';

interface Props {
    list: { firstName: string; lastName: string; phone: string }[]
}

const itemsPerPage = 10;

const WorkerList = (props: Props) => {
    const { list } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState<any>([]); // Your array of items to paginate

    useEffect(() => {
        setItems([...list])
    }, [list])

    const handlePageChange = (event: any, page: any) => {
        setCurrentPage(page);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
    return (
        <Container component="main" maxWidth={false} >
            <Typography variant='h6'>Contact us for work</Typography>
            <Grid sx={{ minHeight: '440px', maxHeight: '440px', overflowX: 'hidden', overflowY: 'scroll' }}>
                {currentItems && currentItems.length > 0 ? currentItems.map((item: any) => {
                    return <Grid sx={{ p: '2px 5px 2px 5px', m: '5px', borderBottom: 1, borderColor: 'divider' }}>
                        <Grid container>
                            <Grid item xs={1}>
                                <Avatar sx={{ bgcolor: '#673ab7', p: '3px', textTransform: 'uppercase', fontSize: '14px' }}>{`${item.firstName?.charAt(0)}${item.lastName?.charAt(0)}`}</Avatar>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography sx={{ pl: '25px', textTransform: 'capitalize' }}>{`${item.firstName} ${item.lastName}`}  <br />{item.phone}</Typography>
                            </Grid>
                        </Grid>
                        <Typography > <Typography sx={{ lineHeight: '.5px !important' }} component={'span'} variant='body1'><b>About me:</b> </Typography>{item?.skills}<br />
                            <Typography sx={{ lineHeight: '.5px !important' }} component={'span'} variant='body1'><b>Address: </b></Typography>{item?.location} </Typography>
                    </Grid>
                }) : 'No Records Found'}
            </Grid>
            {currentItems.length >= 1 && <Grid sx={{ pt: '10px', float: 'right' }}>
                <Pagination
                    color="primary"
                    count={Math.ceil(items.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined" shape="rounded" /></Grid>}
        </Container>
    );
};

export default WorkerList;
