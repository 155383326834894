import React, { useContext, useState } from "react";
import { Button, TextField, Typography, Container, Box, Grid, Link, Checkbox, FormControlLabel } from "@mui/material";
import axios from 'axios';
import { validateEmail } from "../../helper/Helper";
import { useDispatch } from "react-redux";
import { setUserInfo } from "../../store/login/loginSlice";
import Loader from "../../common/Loader";
import { useNavigate } from "react-router-dom";
import AlertPopup from "../../common/AlertPopup";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
interface SignInProps {
    handleChange: any
}
export default function SignIn(props: SignInProps) {

    const { handleChange } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [open, setOpen] = useState<boolean>(false)
    const [savedUser, setSaved] = useState<any>({})
    const { userInfo } = useSelector((state: RootState) => state?.userDetails)
    const [userDetails, setUserDetials] = useState<{ password: string; email: string }>({
        email: '',
        password: ''
    })

    const [formValidation, setFormValidation] = useState<any>({
        email: false,
        password: false
    })
    const handleUserChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserDetials({
            ...userDetails, [name]: value
        })
    }

    const validateForm = () => {
        const formData = { ...userDetails }
        const validate: string[] = [];
        const errorObj = {
            email: false,
            password: false
        }
        if (formData.email === '' || validateEmail(formData.email) === null) {
            errorObj.email = true
            validate.push('error')
        }
        if (formData.password === '') {
            errorObj.password = true
            validate.push('error')
        }
        setFormValidation({ ...errorObj })
        return validate.includes('error') ? false : true
    }

    const handleLogin = async () => {
        if (validateForm()) {
            try {
                setOpen(true)
                const response = await axios.post("login.php", userDetails);
                let obj = JSON.parse(JSON.stringify(userInfo))
                obj.firstName = response.data?.firstName || '';
                obj.lastName = response.data?.lastName || '';
                obj.email = response.data?.email || '';
                dispatch(setUserInfo(obj))
                setOpen(false)
                if (response?.data?.status === "Failed") {
                    setSaved(response.data)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleSaveResponse = () => {
        setSaved({})
    }

    return (
        <Container component="main" maxWidth="xs">
            <AlertPopup open={savedUser?.status ? true : false} onClose={handleSaveResponse} message={savedUser} />
            <Loader open={open} setOpen={setOpen} />
            <div >
                <Typography textAlign={'center'} component="h1" variant="h5" paddingTop={'20px'}>
                    Sign In
                </Typography>
                <form noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleUserChange}
                        error={formValidation.email}
                        helperText={formValidation.email ? 'Please provide the valid Email' : ''}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleUserChange}
                        error={formValidation.password}
                        helperText={formValidation.password ? 'Please fill the Password' : ''}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: '#2c4e69', color: 'white', '&:hover': {
                                backgroundColor: '#2c4e69',
                                color: 'white',
                            },
                        }}
                        onClick={handleLogin}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link sx={{ color: '#2c4e69', textDecoration: 'none', cursor: 'pointer' }} onClick={(event) => handleChange(event, 1)} variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                    <Box mt={5}>
                    </Box>
                </form>
            </div>
        </Container>
    );
}
