import React from 'react';
import { Container, Paper, Typography, Grid, Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

interface Props {
    list: { address: string; description: string; id: string, isDone?:boolean }[];
    handleEdit: (id: string) => void;
    markDone: (id: string) =>Promise<void>
}

const WorkByMe = (props: Props) => {
    const { list, handleEdit, markDone } = props;
    
    return (
        <Container component="main" maxWidth={false} sx={{ minHeight: '470px', maxHeight: '470px', overflowX: 'hidden', overflowY: 'scroll' }}>
            {list && list.length > 0 ? list.map((item) => {
                return < Grid sx={{ p: '5px 10px 5px 10px', m: '5px', borderBottom: 1, borderColor: 'divider', backgroundColor: '#68b1b426' }}>
                   {item?.isDone?"":<><Button onClick={()=>handleEdit(item?.id)} size='small' sx={{
                        float: 'right', '&:hover': {
                            backgroundColor: '#2c4e69',
                            color: 'white',
                        }, backgroundColor: '#2c4e69', color: 'white', ml: '5px'
                    }}>Edit</Button>
                    <Button size='small' onClick={()=>markDone(item?.id)} sx={{
                        float: 'right', backgroundColor: '#2c4e69', color: 'white', '&:hover': {
                            backgroundColor: '#2c4e69',
                            color: 'white',
                        }
                    }}>Mark as done</Button></>} 
                    <Typography pb={'10px'}><strong>Work Description :</strong> {item.description}</Typography>
                    <Typography ><strong>Address :</strong> {item.address}</Typography>
                </Grid>
            }) : 'You have not yet created any work, click on add work to add new work'}
        </Container>
    );
};

export default WorkByMe;
