import React, { useContext, useState } from "react";
import { Button, TextField, Typography, Container, Grid, Link, Checkbox, FormControlLabel } from "@mui/material";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import axios from 'axios';
import { validateEmail } from "../../helper/Helper";
import Loader from "../../common/Loader";
import AlertPopup from "../../common/AlertPopup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
interface SignUpProps {
    handleChange: any
}

interface FormData { firstName: string; lastName: string; password: string; email: string; phone: string }
export default function SignUp(props: SignUpProps) {
    const { userInfo } = useSelector((state: RootState) => state?.userDetails)
    const history = useNavigate()
    const { handleChange } = props
    const [open, setOpen] = useState<boolean>(false)
    const [savedUser, setSaved] = useState<any>({})
    const [userDetails, setUserDetials] = useState<FormData>({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        password: ''
    })
    const [formValidation, setFormValidation] = useState<any>({
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        password: false
    })

    const handleUserChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        setUserDetials({
            ...userDetails, [name]: value
        })
    }
    const validateForm = () => {
        const formData = { ...userDetails }
        const validate: string[] = [];
        const regx = /^[1-9]\d{9}$/
        const errorObj = {
            firstName: false,
            lastName: false,
            email: false,
            password: false,
            phone: false
        }
        if (formData.email === '' || validateEmail(formData.email) === null) {
            errorObj.email = true
            validate.push('error')
        }
        if (formData.phone === '' || !regx.test(formData.phone)) {
            errorObj.phone = true
            validate.push('error')
        }
        if (formData.firstName === '') {
            errorObj.firstName = true
            validate.push('error')
        }
        if (formData.lastName === '') {
            errorObj.lastName = true
            validate.push('error')
        }
        if (formData.password === '') {
            errorObj.password = true
            validate.push('error')
        }
        setFormValidation({ ...errorObj })
        return validate.includes('error') ? false : true
    }

    const handleSaveSignup = async () => {
        if (validateForm()) {
            try {
                setOpen(true)
                let payload = JSON.parse(JSON.stringify(userDetails))
                payload.lattitude = userInfo?.lat || 0;
                payload.longitude = userInfo?.long || 0;
                payload.isWorker = false
                const response = await axios.post("signup.php", payload);
                setSaved(response.data)
                setOpen(false)
            } catch (error) {
                console.log(error)
            }
        }
    }

    const handleSaveResponse = () => {
        setSaved({})
    }

    return (
        <Container component="main" maxWidth="xs">
            <AlertPopup open={savedUser?.status ? true : false} onClose={handleSaveResponse} message={savedUser} />
            <Loader open={open} setOpen={setOpen} />
            <div >
                <Typography textAlign={'center'} component="h1" variant="h5" paddingTop={'20px'}>
                    Register
                </Typography>
                <form noValidate>
                    <Grid container spacing={2} paddingTop={'5px'}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                                size="small"
                                onChange={handleUserChange}
                                error={formValidation.firstName}
                                helperText={formValidation.firstName ? 'Please fill the First Name' : ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                size="small"
                                onChange={handleUserChange}
                                error={formValidation.lastName}
                                helperText={formValidation.lastName ? 'Please fill the Last Name' : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="phone"
                                label="Phone Number"
                                name="phone"
                                size="small"
                                onChange={handleUserChange}
                                error={formValidation.phone}
                                helperText={formValidation.phone ? 'Please provide the valid Phone Number' : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                size="small"
                                onChange={handleUserChange}
                                error={formValidation.email}
                                helperText={formValidation.email ? 'Please provide the valid Email' : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                size="small"
                                onChange={handleUserChange}
                                error={formValidation.password}
                                helperText={formValidation.password ? 'Please fill the Password' : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked value="" color="primary" />}
                                label="Privacy policy"
                                onClick={()=>history('/policy')}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{
                            backgroundColor: '#2c4e69', color: 'white', '&:hover': {
                                backgroundColor: '#2c4e69',
                                color: 'white',
                            },
                        }}
                        onClick={handleSaveSignup}
                    >
                        Sign Up
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link onClick={(event) => handleChange(event, 0)} variant="body2" sx={{ color: '#2c4e69', textDecoration: 'none', cursor: 'pointer' }}>
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Container>
    );
}
