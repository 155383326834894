import React from "react";
import { Container, Typography } from "@mui/material";

const Privacypolicy = () => {
    return <Container >
        <Typography sx={{ p: '20px' }}>
            <Typography variant="h6" sx={{fontWeight:600}}>Privacy Policy</Typography>

            This Privacy Policy describes how [www.itsdone.co.in] collects, uses, and protects the personal information you provide on our website.
            <br/>  <br/>
            <Typography variant="h6" sx={{fontWeight:600}}>1. Information We Collect</Typography>
            <Typography p={'10px'}>
                We collect personal information that you voluntarily provide to us when using our website, including but not limited to:
                <Typography >Contact information (such as name, email address, phone number)</Typography>
                <Typography >Profile information (such as skills, experience, portfolio)</Typography>
            </Typography>
            <Typography variant="h6" sx={{fontWeight:600}}>2. How We Use Your Information</Typography>
            <Typography p={'10px'}>
                We use the information we collect for the following purposes:

                To create and manage your user account
                To facilitate communication between freelancers and clients
                To process payments for services rendered
                To improve our website and services
                To comply with legal obligations
            </Typography>


            <Typography variant="h6" sx={{fontWeight:600}}>3. Sharing of Information</Typography>
            <Typography p={'10px'}>
                We may share your personal information with third parties only in the following circumstances:

                With your consent
                To facilitate the services you request
                To comply with legal obligations or enforce our policies
                In connection with a business transaction (e.g., merger, acquisition)
            </Typography>


            <Typography variant="h6" sx={{fontWeight:600}}>4. Data Security</Typography>
            <Typography p={'10px'}>
                We take appropriate measures to protect the security of your personal information, including encryption, access controls, and regular security assessments.
            </Typography>
            <Typography variant="h6" sx={{fontWeight:600}}>5. Your Rights</Typography>
            <Typography p={'10px'}>
                You have the right to access, correct, or delete your personal information. You may also request that we restrict the processing of your information or object to certain processing activities.
            </Typography>

            <Typography variant="h6" sx={{fontWeight:600}}>6. Cookies and Tracking Technologies</Typography>
            <Typography p={'10px'}>
                We use cookies and similar technologies to enhance your experience on our website and to analyze usage patterns. You can manage your cookie preferences through your browser settings.

            </Typography>


            <Typography variant="h6" sx={{fontWeight:600}}>7. Changes to this Privacy Policy</Typography>

            <Typography p={'10px'}>
                We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on our website.
            </Typography>

            <Typography variant="h6" sx={{fontWeight:600}}>8. Contact Us</Typography>
            <Typography p={'10px'}>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at [sharmavishnu08@gmail.com].
            </Typography>
        </Typography>
    </Container>
}

export default Privacypolicy