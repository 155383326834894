


import React, { useContext, useState } from 'react';
import { Typography, Grid, Container, Paper, Tabs, Tab } from '@mui/material';
import SignUp from '../login/SignUp';
import SignIn from '../login/SignIn';
import Home from './Home';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/rootReducer';

const styles = {
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        display: 'flex',
    },
    leftContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '540px',
    },
    tabContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'left',
        alignItems: 'center',
        padding: '20px',
        minHeight: '540px',
    },
    tabButton: {
        minWidth: '100px',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: '#2c4e69 !important',
        color: 'white',
    },
};

const Content = () => {
    const [tabValue, setTabValue] = useState(0);
    const { userInfo } = useSelector((state: RootState) => state?.userDetails)
    const handleChange = (event: any, newValue: any) => {
        setTabValue(newValue);
    };

    return (
        <Container maxWidth={false} >
            {userInfo?.email ? <Home /> :
                <Paper sx={{ p: '15px', m: '15px' }}>
                    <div style={styles.root}>
                        <Grid container>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={styles.leftContainer}>
                                <Typography className='typewriter' variant="body1" textAlign={'left'}>
                                    <Typography variant="h4" gutterBottom textAlign={'left'}>
                                        Welcome to Get It Done
                                    </Typography>
                                    <Typography component={'p'}>
                                        Signup to Create and Explore the freelance work.
                                        <br /> <br />
                                        Discover part-time and freelance opportunities near you! <br />Allow your location to find flexible work options that fit your skill and schedule. Whether you're a student, parent, or just seeking extra income, we've got the perfect job for you. Say goodbye to endless scrolling—start your search today.
                                        <br /><br />
                                        <strong>Connect, Help others, and make some money, in your free time.</strong>
                                    </Typography>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={styles.tabContainer}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    centered
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    sx={{ width: '100%', textTransform: 'capitalize', "& .MuiTabs-indicator": { display: "none" } }}
                                >
                                    <Tab style={{ width: '100%', textTransform: 'capitalize', backgroundColor: '#cfc6c6' }} sx={tabValue === 0 ? styles.tabButton : null} label="Sign In" />
                                    <Tab style={{ width: '100%', textTransform: 'capitalize', backgroundColor: '#cfc6c6' }} sx={tabValue === 1 ? styles.tabButton : null} label="Sign Up" />
                                </Tabs>
                                {tabValue === 0 && <SignIn handleChange={handleChange} />}
                                {tabValue === 1 && <SignUp handleChange={handleChange} />}
                            </Grid>
                        </Grid>
                    </div>
                </Paper>}
        </Container>
    );
};

export default Content;
