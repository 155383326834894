import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

interface Props {
    open: boolean;
    saveLable?: string;
    handleClose: ()=> void;
    cancelLable?: string;
    header: string;
    children: React.ReactNode;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    handleCancel: ()=> void;
    handleSave: ()=> void;
}

export default function ModalPopup(props: Props) {
    const { open, saveLable, handleClose, cancelLable, header, children, maxWidth, handleSave, handleCancel } = props;

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                maxWidth={maxWidth || 'sm'}
            >
                <DialogTitle sx={{ m: 0, p: 2, backgroundColor:'#2c4e69 !important', color:'white' }} id="customized-dialog-title">
                    {header || 'Title'}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{borderTop: 1, borderColor: 'divider'}}>
                    <Typography gutterBottom>
                        {children}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button sx={{color:'#2c4e69 !important'}} onClick={handleCancel}>
                        {cancelLable || 'Cancel'}
                    </Button>
                    <Button sx={{color:'#2c4e69 !important'}} onClick={handleSave}>
                        {saveLable || 'Save'}
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </React.Fragment>
    );
}
