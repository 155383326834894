import React, { useState, useEffect } from 'react';
import { Container, Paper, Typography, Grid, Button } from '@mui/material';
import Pagination from '@mui/material/Pagination';
interface Props {
    list: { address: string; description: string }[]
}

const itemsPerPage = 10;

const WorkList = (props: Props) => {
    const { list } = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState<any>([]); // Your array of items to paginate

    useEffect(() => {
        setItems([...list])
    }, [list])

    const handlePageChange = (event: any, page: any) => {
        setCurrentPage(page);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);
    return (
        <Container component="main" maxWidth={false} >
            <Grid sx={{ minHeight: '470px', maxHeight: '470px', overflowX: 'hidden', overflowY: 'scroll' }}>
                {currentItems && currentItems.length > 0 ? currentItems.map((item: any) => {
                    return < Grid sx={{ p: '5px 10px 5px 10px', m: '5px', borderBottom: 1, borderColor: 'divider', backgroundColor: '#68b1b426' }}>
                        <Typography pb={'5px'}><strong>Work Description :</strong> {item.description}</Typography>
                        <Typography ><strong>Address :</strong> {item.address}</Typography>
                    </Grid>
                }) : 'No Work available at your location'}
            </Grid>
            {currentItems.length >= 1 && <Grid sx={{pt:'10px', float:'right'}}>
                <Pagination
                color="primary"
                    count={Math.ceil(items.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    variant="outlined" shape="rounded" /></Grid>}

        </Container>
    );
};

export default WorkList;
