import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Props {
    open: boolean;
    setOpen: any
}

const Loader = (props: Props) => {
    const { open, setOpen } = props;
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div>
            <Backdrop
                sx={{ color: '#2c4e69', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
                onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
}

export default Loader;
