import { createSlice } from "@reduxjs/toolkit";

interface LoginSliceProps {
    userInfo: { firstName: string; lastName: string; email: string; lat?:string; long?: string }
}

const initialState: LoginSliceProps = {
    userInfo: {
        firstName: '', lastName: '', email: '', lat: "0", long: "0"
    }
}

const loginSlice = createSlice({
    name: 'user_info',
    initialState,
    reducers: {
        setUserInfo: (state, action) => {
            state.userInfo = action.payload;
        }
    }
})

export const { setUserInfo } = loginSlice.actions;
export default loginSlice.reducer;